import get from 'lodash/get'
import qs from 'qs'
import changeCaseObject from 'change-case-object'
import moment from 'moment'
import nonJsonApiClient from './nonJsonApiClient'
import { SERVER_DATE } from '../../constants/formats'
import client from './client'
import { mockAirDnaResponse } from './requestMocks'
import * as transform from '../node/transform'
import { format } from 'date-fns'
import { CancelTokenSource } from 'axios'
import { ScheduleTourPayload, ScheduleTourUserInputData } from '../../types/scheduleTour'
import { snakeCaseKeys } from '../../helpers/utils'
import { VIEW } from '../../constants/client/clientEvents'

const TODAY = moment().format(SERVER_DATE)
const ONE_YEAR_AGO = moment().subtract(1, 'year').format(SERVER_DATE)
const defaultDate = moment().toJSON()

const fetchHomeDetail = ({ homeId }) => client.get(`/frontend/detailed_homes/${homeId}`)

export const getHomeDetail = transform.homeDetail(fetchHomeDetail)

export const getClientData = (clientId, includeProfile = true) => {
  let include: string[] = []

  if (includeProfile) {
    include = [
      'customer-profile',
      'customer-profile.office-profile',
      'customer-profile.office-profile.corporate-profile',
      'client-team-memberships',
      ...include
    ]
  }

  return client.get(`clients/${clientId}?include=${include.join(',')}`)
}

export const getBuyerInfoData = (clientId, includeBuyerInfo = true) => {
  let include: string[] = []

  if (includeBuyerInfo) {
    include = ['market-interests', 'prequalifications', ...include]
  }

  return client.get(`buyers/${clientId}?include=${include.join(',')}`)
}

export const getClientAnswers = clientId => client.get(`clients/${clientId}/client-answers`)

export const getCustomerProfile = profileId => {
  const include = ['office-profile', 'office-profile.corporate-profile']

  return client.get(`customer-profiles/${profileId}?include=${include.join(',')}`)
}

export const getCustomerProfileSettings = profileId => {
  return client.get(`customer-profiles/${profileId}/settings`)
}

export const getCustomerProfilePowerUps = customerId => client.get(`customer-profiles/${customerId}/power-ups`)

export const createDigest = ({ clientId, email }) =>
  client.post(`frontend/digests`, {
    email,
    client_id: clientId
  })

const buyerAction = verb => (id, data) =>
  client[verb](`buyers/${id}`, {
    data: {
      id,
      type: 'buyers',
      ...data
    }
  })

const patchBuyer = buyerAction('patch')
const putBuyer = buyerAction('put')

export const setMarketInterest = (clientId, zipcode) =>
  client.post(`clients/${clientId}/market-interests`, {
    data: {
      type: 'market-interests',
      attributes: { zipcode }
    }
  })

export const removeMarketInterest = (buyerId, id) => client.delete(`buyers/${buyerId}/market-interests/${id}`)

export const setBuyerSettings = (id, data) =>
  patchBuyer(id, {
    attributes: changeCaseObject.paramCase(data)
  })

export const putBuyerSettings = (id, data) =>
  putBuyer(id, {
    attributes: data
  })

export const fetchMortgageRates = (term = 30) => {
  const params = qs.stringify({
    page: {
      size: 52
    },
    filter: {
      term,
      'mortgage-type': 'FIX',
      'date-range': `${ONE_YEAR_AGO}..${TODAY}`
    }
  })
  return client.get(`mortgage-rates?${params}`)
}

export const fetchLatestMortgageRates = () => {
  const params = qs.stringify({
    filter: {
      latest: true,
      available_products: true
    }
  })
  return client.get(`mortgage-rates?${params}`)
}

export const fetchSponsor = (agentType, agentId) => client.get(`${agentType}/${agentId}/sponsor`)

export const fetchAirDna = async ({ address, validationToken }) => {
  const MOCK = process.env.REACT_APP_MOCK_AIRDNA === 'true'

  if (MOCK) {
    return mockAirDnaResponse()
  }

  const params = qs.stringify({
    rental_potential: {
      address
    },
    validation_token: validationToken
  })

  const response = await client.post(`search/rental_potential?${params}`)

  return response
}

export const fetchAutoComplete = async addressData => {
  const response = await nonJsonApiClient.get(`/autocomplete/search-address?data[address]=${addressData}`)

  return response
}

const getElasticRecordsFromResponse = response => get(response, 'data.hits', []).map(i => i.source)

export const marketsByBoundaryRequest = async params => {
  const response = await client.get('/markets/boundaries', { params })
  return getElasticRecordsFromResponse(response)
}

export const marketsByZipcodesRequest = async zipcodes => {
  const response = await client.get(`markets/zipcodes/${zipcodes.join(',')}`)
  return getElasticRecordsFromResponse(response)
}

export const getAverageMedianPrice = async ({ lat = null, lng = null } = {}) => {
  const path = `markets/average-median-price?lat=${lat}&lng=${lng}`

  const response = await client.get(path)
  return response
}

interface GetClientEventParams {
  clientId: string
  eventName: string
  eventSource: string
  limit?: number
}

const getClientEvent = ({ clientId, eventName, eventSource, limit = 1 }: GetClientEventParams) => {
  const response = client.get(
    `/clients/${clientId}/client-events?filter[name]=${eventName}&filter[source]=${eventSource}&sort=-created-at&page[limit]=${limit}`
  )

  return response
}

export const getClientSmsLongCode = clientId => {
  const response = client.get(
    `/clients/${clientId}/client-events?filter[name]=sent&filter[source]=sms&sort=-created-at&page[limit]=1`
  )
  return response
}

export const getClientViewedDigest = clientId =>
  getClientEvent({
    clientId,
    eventName: VIEW,
    eventSource: 'home-digest'
  })

export const patchClientAttributeFields = (id, attrs = {}, source?: CancelTokenSource) =>
  client.patch(
    `clients/${id}`,
    {
      data: {
        type: 'clients',
        id,
        attributes: {
          ...attrs
        }
      }
    },
    {
      cancelToken: source?.token
    }
  )

export const enableHBB = (id, attrs = {}) =>
  client.patch(`clients/${id}`, {
    data: {
      type: 'clients',
      id,
      attributes: {
        'hbb-access': true,
        ...attrs
      }
    }
  })

export const createAnswer = (clientId, { question, response }) =>
  client.post(`clients/${clientId}/client-answers`, {
    data: {
      type: 'client-answers',
      attributes: {
        question,
        response
      }
    }
  })

export const updateAnswer = (clientId, { id, question, response }) =>
  client.patch(`clients/${clientId}/client-answers/${id}`, {
    data: {
      type: 'client-answers',
      id,
      attributes: {
        question,
        response
      }
    }
  })

export const getHomeInsurance = homeId => client.get(`homes/${homeId}/home-insurance`)

export const getHomeSellingInfo = homeId => client.get(`homes/${homeId}/home-selling-info`)

export const createHome = ({ clientId, home }) =>
  client.post(`clients/${clientId}/homes`, {
    data: {
      type: 'homes',
      attributes: {
        'address-street': home?.addressLine1,
        'address-unit': home?.unitNumber,
        'address-zip': home?.zipCode
      }
    }
  })

export const addHomeAnswerRequest = ({ homeUuid, answerData }) =>
  client.post(`homes/${homeUuid}/home-answers`, {
    data: {
      type: 'home-answers',
      attributes: {
        question: answerData.question,
        response: answerData.answer
      }
    }
  })

export const updateHomeRequest = (homeUuid, attributes) =>
  client.patch(`homes/${homeUuid}`, {
    data: {
      type: 'homes',
      id: homeUuid,
      attributes: changeCaseObject.paramCase(attributes)
    }
  })

export const submitLoanBalance = (loanId, value, date) =>
  client.post(`loans/${loanId}/loan-balances`, {
    data: {
      type: 'loan-balances',
      attributes: {
        source: 'user',
        value,
        'effective-date': date || defaultDate
      }
    }
  })

export const postValuationAmountForHomeUuid = (homeUuid, amount, type) =>
  client.post(`homes/${homeUuid}/valuations`, {
    data: {
      type: 'valuations',
      attributes: {
        amount,
        'valuation-type': type
      }
    }
  })

export const getValuationHistory = (clientId, homeId) =>
  client.get(`clients/${clientId}/valuation-history?home_id=${homeId}`)

export const createTcaForClient = (homeId, clientId, refiData) =>
  client.post(`integrations/mortgage_coach/tca`, {
    data: {
      type: 'tcas',
      attributes: {
        'home-id': homeId,
        'client-id': clientId,
        'loan-term-years': refiData.term,
        'loan-type': refiData.type,
        rate: refiData.rate,
        'arm-years-initial': refiData.years
      }
    }
  })

export const createClientInviteEmail = attrs =>
  client.post(`/clients/${attrs.clientId}/client-invite-email`, {
    data: {
      type: 'client-invite-emails',
      attributes: {
        email: attrs.email,
        'first-name': attrs.firstName,
        'last-name': attrs.lastName
      }
    }
  })

export const getCustomMailer = customMailerId => client.get(`custom-mailers/${customMailerId}`)

interface GetRecommendationsParams {
  clientId: string
  homeUuid: string
}

export const getRecommendations = ({ clientId, homeUuid }: GetRecommendationsParams) =>
  client.get(`clients/${clientId}/homes/${homeUuid}/recommendations?limit=3`)

export const getVideos = () => client.get(`/videos?page[size]=100`)

export const getShowPmiModules = (clientId: string, homeUuid: string) =>
  client.get(`clients/${clientId}/homes/${homeUuid}/pmi`)

export const getClientHomes = clientId => {
  const params = qs.stringify({
    page: {
      limit: 100,
      size: 50
    },
    params: {
      fields: {
        homes: ['legacy-ref', 'address-street', 'address-unit', 'address-zip']
      }
    }
  })
  return client.get(`clients/${clientId}/homes?${params}`)
}

export const getBrandingStats = ({ sponsorProfileId, zip }) =>
  client.get(`/reports/branding-stats/${sponsorProfileId}?zipcode=${zip}`)

export const createHomeownerMessage = (homeId, { topic, topicKey, message, clientId }, trackingClient) => {
  const resp = client.post(`/homes/${homeId}/messages`, {
    data: {
      type: 'messages',
      attributes: {
        topic,
        message,
        'topic-key': topicKey,
        'client-id': clientId
      }
    }
  })
  trackingClient?.questionAsked({ direct_message_topic: topicKey, platform: 'web' })
  return resp
}

export const createBuyerMessage = (id, { topic, topicKey, message, metadata }, trackingClient) => {
  const resp = client.post('buyers/messages', {
    data: {
      type: 'messages',
      attributes: {
        'client-id': id,
        'topic-key': topicKey,
        topic,
        message,
        metadata
      }
    }
  })
  trackingClient?.questionAsked({ direct_message_topic: topicKey, platform: 'web' })
  return resp
}

export const getLocations = (queryParams: any) => {
  const paramString = qs.stringify(queryParams)
  return nonJsonApiClient.get(`autocomplete/locations?${paramString}`)
}

export const createLoan = (homeUuid, data) =>
  client.post(`/homes/${homeUuid}/loans`, {
    data: {
      type: 'loans',
      attributes: changeCaseObject.paramCase(data)
    }
  })

export const deleteLoan = loanUuid => client.delete(`/loans/${loanUuid}`)

export const recompileClient = clientId => {
  return client.put(`/clients/${clientId}/recompile`)
}

export const updateLoan = (loanUuid, data) =>
  client.put(`/loans/${loanUuid}`, {
    data: {
      type: 'loans',
      id: loanUuid,
      attributes: changeCaseObject.paramCase(data)
    }
  })

export const restoreLoan = loanUuid => client.post(`/loans/${loanUuid}/restore`)

export const submitHomePriceIndexAnswers = (clientId, homeUuid, values) => {
  return client.post(`/clients/${clientId}/homes/${homeUuid}/home_price_index_home_answers`, {
    home_price_index_home_answers: changeCaseObject.snakeCase(values)
  })
}
