import client from '../client'

export const createClientTeamMembershipRequest = (clientId, { firstName, lastName, email, isVerified }) =>
  client.post(`/clients/${clientId}/hbn-team-memberships`, {
    client_team_membership: {
      added_by: 'client',
      is_verified: isVerified,
      customer_profile: {
        first_name: firstName,
        last_name: lastName,
        email
      }
    }
  })

export const verifyClientTeamMembershipRequest = (clientId, clientTeamMembershipId) =>
  client.put(`/clients/${clientId}/hbn_team_memberships/${clientTeamMembershipId}/verify`)
